import React from 'react';
import './homepage.css';
import Navbar from '../../commons/navbar/navbar';
import { useHistory } from 'react-router-dom';
import Footer from '../../commons/footer/footer';

export default function Homepage() {
  const history = useHistory();
  return (
    <>
      <header>
        <Navbar navElement='home' />
      </header>
      <main>
        <section class='buttonSection'>
          <div onClick={() => history.push('/portraits')} class="buttonContainer">
            <img class='imageButton' src='images/buttons/portraitbutton.jpeg'
                 alt='Portrait button' />
            <p class="buttonText">Portraits</p>
          </div>
          <div onClick={() => history.push('/dotwork')} class="buttonContainer">
            <img class='imageButton dotworkImage'
                 src='images/buttons/dotworkbutton.JPG'
                 alt='Dotwork button' />
            <p class="buttonText dotworkButton">Dotwork</p>
          </div>
        </section>
      </main>
      <footer>
        <Footer/>
      </footer>
    </>);
}