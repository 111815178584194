import './App.css';
import { Route, Switch } from 'react-router-dom';
import Homepage from './components/pages/homepage/homepage';
import Portraitspage from './components/pages/portraitspage/portraitspage';
import Dotworkpage from './components/pages/dotworkpage/dotworkpage';
import Aboutpage from './components/pages/aboutpage/aboutpage';
import { ThemeProvider } from '@mui/material';
import { theme } from './theme/theme';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <div className='App'>
        <Switch>
          <Route path={'/about'} component={Aboutpage} />
          <Route path={'/portraits'} component={Portraitspage} />
          <Route path={'/dotwork'} component={Dotworkpage} />
          <Route path={['/', '/home']} component={Homepage} />
        </Switch>
      </div>
    </ThemeProvider>
  );
}

export default App;
