import React from 'react';
import './portraitspage.css';
import Navbar from '../../commons/navbar/navbar';
import Footer from '../../commons/footer/footer';

export default function Portraitspage() {

  return (
    <>
      <header>
        <Navbar navElement='portraits' />
      </header>
      <main class="imageContainer">
        <img class="image" src='/images/portraits/IMG_9BA2F3F14592-1.jpeg' alt='First portrait' />
        <img class="image" src='/images/portraits/IMG_159E43E65862-1.jpeg' alt='Second portrait' />
      </main>
      <footer>
        <Footer />
      </footer>
    </>
  );
}