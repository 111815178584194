import React from 'react';
import './dotworkpage.css';
import Navbar from '../../commons/navbar/navbar';
import Footer from '../../commons/footer/footer';

export default function Dotworkpage() {

  return (
    <>
      <header>
        <Navbar navElement='dotwork' />
      </header>
      <main className="imageContainer">
        <img className="image" src='/images/dotwork/055D059E-5EC4-4FBC-B72B-3B05B8877F90.jpg' alt='First dotwork' />
        <img className="image" src='/images/dotwork/DAF3F4BB-2D51-462D-A10B-3FB7D83C7B85.JPG' alt='Second dotwork' />
        <img className="image" src='/images/dotwork/IMG_1479.JPG' alt='Third dotwork' />
        <img className="image" src='/images/dotwork/IMG_1483.JPG' alt='Fourth dotwork' />
      </main>
      <footer>
        <Footer />
      </footer>
    </>
  );
}