import React from 'react';
import './aboutpage.css';
import Navbar from '../../commons/navbar/navbar';
import Footer from '../../commons/footer/footer';

export default function Aboutpage() {

  return (
    <>
      <header>
        <Navbar navElement="about"/>
      </header>
      <main class="main-container">
        <img class="my-image" src="images/IMG_1544.JPG" alt="A picture of me"/>
        <div class="text">
          <p>Hi!</p>
          <p>I am Marie, an artist and piano player from Germany.</p>
          <p>This is my current collection of drawings. I hope you enjoy them.</p>
          <p>If you are interested in a personal picture or would like to see more content, you can follow me on instagram
          or send me an email!</p>
        </div>
      </main>
      <footer>
        <Footer/>
      </footer>
    </>
  );
}