import React from 'react';
import './footer.css';
import { SocialIcon } from 'react-social-icons';

export default function Footer() {
  return (
    <>
      <section class='footer'>
        <div class="signature">
          <p>Marie Keller Art</p>
          <p>by Marie Keller</p>
        </div>
        <div class='social-icons-container'>
          <div class='social-icon'>
            <p>Follow me</p>
            <SocialIcon bgColor='white' network='instagram'
                        url='https://instagram.com/mariekellerart?utm_medium=copy_link' />
          </div>
          <div class='social-icon'>
            <p>Send a message</p>
            <SocialIcon bgColor='white' network='email' url='mailto:marie.keller.98@web.de' />
          </div>
        </div>
      </section>
    </>
  );
}