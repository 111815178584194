import React, { useEffect } from 'react';
import './navbar.css';
import { useHistory } from 'react-router-dom';

export default function Navbar(props) {

  const history = useHistory();

  useEffect(() => {
    const navElement = props.navElement;
    const element = document.getElementById(navElement);
    element.classList.add("selectedNavElement");
  });

  return (
    <>
      <nav class="navbar">
        <div class="navElement" id="home" onClick={() => history.push('/home')}>Home</div>
        <div class="navElement" id="portraits" onClick={() => history.push('/portraits')}>Portraits</div>
        <div class="navElement" id="dotwork" onClick={() => history.push('/dotwork')}>Dotwork</div>
        <div class="navElement" id="about" onClick={() => history.push('/about')}>About</div>
      </nav>
      <div class="placeholder"/>
    </>
  );
}